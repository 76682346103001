<template>
  <div class="protocol-content">
    <p style="text-align: justify" v-for="protocol in protocols"> {{ protocol }}</p>
  </div>
</template>

<script>
  export default {
    name: "ProtocolContent",
    props: {
    },
    computed: {
      lang(){
        const { locale } = this.$i18n;
        return locale;
      },
      protocols(){
        const { lang } = this;
        return this[lang];
      },
    },
    data(){
      return {
        "zh-UG": [
          `بىرىنجى: تىزىملىتىش ۋە ھېسابات بىخەتەرلىكى`,
          `1. ئىشلەتكۈچى ھەقىقىي نامدا تىزىملىتىلىشى شۇنداقلا ئۇچۇرلارنىڭ ھەقىقىيلىقىغا كاپالەتلىك قىلىشى كېرەك`,
          `2. ئىشلەتكۈچى ھېسابات ئۇچۇرى ۋە پارولنى ياخشى ساقلىشى كېرەك، شەخىس تۈپەيلى كېلىپ چىققان ھېسابات ئوغۇرلىنىش قاتارلىق ئىشلارنىڭ سۇپا بىلەن مۇناسىۋىتى يوق.`,
          ``,
          `ئىككىنجى: مەزمۇن يوللاش ۋە نەشىر ھوقوقى`,
          `1. ئىشلەتكۈچى دۆلىتىمىزنىڭ مۇناسىۋەتلىك قانون - نىزاملىرىغا ئاڭلىق رىئايە قىلىپ، سىياسىغا چېتىلىدىغان، سېرىق تۈس ئالغان، زوراۋانلىق تۈس ئالغان ھەرقانداق مەزمۇن يوللىماسلىقى كېرەك.`,
          `2. ئىشلەتكۈچى يوللىغان مەزمۇننىڭ چىن، توغرا، قانونلۇق ئىكەنلىكىگە كاپالەتلىك قىلىشى كېرەك، ئەگەردە يوللىغان مەزمۇنى باشقىلارنىڭ نەشىر ھوقوقىغا دەخلى - تەرۇز قىلسا مۇناسىۋەتلىك قانونى مەسئۇلىيەتنى ئۆز ئۈستىگە ئېلىشى كېرەك.`,
          `3. ئىشلەتكۈچى يوللىغان مەزمۇندا، باشقىلارنىڭ ئەسىرىنى ئىشلەتكەن بولسا، ئەسەرنىڭ كېلىش مەنبەسىنى ئېنىق ئەسكەرتىشى كېرەك، مەزمۇن ئىچىگە باشقىلارنىڭ ئەسىرىنى ھوقوقسىز قىستۇرماسلىقى كېرەك.`,
          ``,
          `ئۈچىنجى: ئىشلەتكۈچى ھوقوقى ۋە مەجبۇرىيىتى`,
          `1. ئىشلەتكۈچى مەزكۇر سۇپىغا مەزمۇن يوللاش ۋە بەھىرلەش ھوقوقىغا ئېگە`,
          `2. ئىشلەتكۈچى يوللىغان مەزمۇنى باشقىلارنىڭ بىلىم مۈلۈك ھوقوقى قاتارلىق قانونى ھوقوق مەنپەئەتىگە دەخلى - تەرۇز قىلمىغانلىقىغا كاپالەتلىك قىلىشى كېرەك.`,
          `3. ئىشلەتكۈچى مەزكۇر سۇپىدىن پايدىلىنىپ ھەرقانداق قانونسىز پائالىيەت بىلەن شۇغۇللانماسلىققا كاپالەتلىك قىلىشى كېرەك، مەسىلەن ئالدامچىلىق دىگەندەك.`,
          ``,
          `تۆتىنجى: جاۋاپكارلىقنى كەچۈرۈم قىلىش باياناتى`,
          `1. مەزكۇر سۇپا ئىشلەتكۈچى سەۋەبىدىن كېلىپ چىققان بىۋاستە ۋە ۋاستىلىك ھەرقانداق قانونى ۋە ئىقتىسادى زىيانغا مەسئۇل بولمايدۇ.`,
          `2. مەزكۇر سۇپا ئىشلەتكۈچىنىڭ يوللىغان مەزمۇنىغا قارىتا ئالدىن تەستىقلاش تەكشۈرۈش خىزمىتىنى قىلمايدۇ، ئەگەردە باشقىلارنىڭ ھوقوق - مەنپەئەتىگە دەخلى - تەرۇز قىلغان قىلمىش بولسا، پۈتۈنلەي ئىشلەتكۈچىنىڭ مەسئۇلىيىتى، سۇپا بىلەن مۇناسىۋىتى يوق.`,
          ``,
          `بەشىنجى: كېلىشىمنامىنى ئۆزگەرتىش`,
          `1. مەزكۇر سۇپا ھەرقانداق ۋاقىتتا كېلىشىمنامە مەزمۇنىنى ئۆزگەرتىش ھوقوقىغا ئېگە، كېلىشىمنامە ئۆزگەرتىلگەن ھامان، مۇناسىۋەتلىك جايلاردا يېڭى كېلىشىمنامە مەزمۇنى كۆرۈنىدۇ.`,
          `2. ئىشلەتكۈچى مەزكۇر سۇپىنى ئىشلىتىش جەريانىدا ھەرۋاقىت ئىشلىتىش كېلىشىمنامىسىگە دېققەت قىلىشى ۋە ئەڭ يېڭى ئىشلىتىش كېلىشىمنامىسىگە رىئايە قىلىشى كېرەك.`,
        ],
        "zh-CN": [
          `一、用户注册及账号安全`,
          `1、用户应以真实身份进行注册，并确保信息的真实性和完整性。`,
          `2、用户应妥善保管个人账号及密码，因个人原因造成的账户被盗，与本平台无关。`,
          ``,
          `二、内容发布及版权问题`,
          `1、用户应遵守国家有关法律法规，不得发布涉及政治、色情、暴力等相关内容。`,
          `2、用户发布的内容需担保其所发布的内容真实、准确、合法，如涉及侵权行为，需自行承担法律责任。`,
          `3、用户发表文章或其他作品时应注明出处，不得发表未经授权的内容。`,
          ``,
          `三、用户权利及义务`,
          `1、用户享有在本平台上发布和分享内容的权利。`,
          `2、用户需要保证所发布内容不会侵犯他人的知识产权等权利。`,
          `3、用户应保证不会利用本平台从事任何非法活动，如诈骗等。`,
          ``,
          `四、免责声明`,
          `1、本平台不承担用户因使用本平台而引发的直接或间接损失。`,
          `2、本平台不对用户发布的内容进行事前审查，如涉及侵权行为，需受到法律制裁。`,
          ``,
          `五、协议修改`,
          `1、本平台有权随时修改协议内容，一旦协议发生变动，将在相关页面上提示修改内容。`,
          `2、用户应在使用本服务时，随时关注并遵守最新的服务协议。`,
        ]
      }
    },
  }
</script>

<style scoped>

</style>
